import { RunningLine } from 'components/RunningLine'

import { HelmetFunc } from 'components/PageMetaData'

import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { DefaultFormBlock } from 'blocks/DefaultFormBlock'
import { gtag, sendEvent } from 'tracking'
import { Header } from 'blocks/Header/Header'
import { graphql } from 'gatsby'
import { usePagePath } from 'hooks/usePagePath'

import { useFormHandler } from 'hooks/useFormHandler'
import { EmailInput } from 'components/form/EmailInput'
import { Form, IChildrenProps } from 'components/form/Form'
import { MessageInput } from 'components/form/MessageInput'
import { PhoneInput } from 'components/form/PhoneInput'
import { Container } from 'components/Container'
import { indent } from 'styles/indent'
import {
    ProjectQuestionNew,
    ProjectQuestionNewDesktop,
} from 'blocks/ProjectQuestionNew'
import { PosadkaLinkBlock } from 'components/PosadkaLinkBlock'
import { MobileMenuPosadka } from 'components/MobileMenuPosadka'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { displayWidth } from 'styles/width'

const Desktop = styled.div`
    position: relative;
    display: none;
    width: 100%;
    height: 100%;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
    }
`
const Wrap = styled.div`
    top: 66px;
    height: calc(100vh - 126px);
    left: 0;
    right: 0;
    bottom: 60px;
    position: absolute;
    overflow: auto;
    @media (min-width: ${displayWidth.tablet}) {
        display: none;
    }
`
const WrapDesktop = styled.div`
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
        top: 80px;
        height: calc(100vh - 80px);
        left: 0;
        right: 0;
        bottom: 0px;
        position: absolute;
        overflow: auto;
    }
`

const InputBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    input {
        width: 250px;
        margin: 10px auto;
    }
    textarea {
        margin: 10px auto;
    }
`
const FormTitle = styled.div`
    font-family: 'Yeseva One', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 1px;
    color: ${colors.accentText};
    text-align: center;
    margin: 10px;
    p {
        margin-top: 5px;
        text-transform: uppercase;
    }
`
const PromoHeroColumn = styled.div`
    :first-child {
        display: flex;
    }
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid ${colors.dark};
    border-right: 1px solid ${colors.dark};
    margin-bottom: 50px;
    flex-grow: 0;
    button {
        width: 68%;
        margin: 10px auto 30px;
        height: 60px;
        background-color: ${colors.accentText};
    }
`
const ContainerStyled = styled(Container)`
    padding: 0 ${indent.mobile};
    box-sizing: border-box;
`

const pageMetadata = {
    uk: {
        title: 'Дизайн інтер`єру офісу від 1$ м²',
        description:
            "Виконаємо дизайн проект інтер'єру усієї квартири за 99 доларів США",
    },
    ru: {
        title: 'Дизайн интерьера офиса от 1$ м²',
        description:
            'Выполним дизайн проект интерьера всей квартиры за 99 долларов США',
    },
    en: {
        title: 'Office interior design from 1$ m²',
        description:
            'We will design the interior project of the entire apartment for 99 US dollars',
    },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Posadka = ({ data }: { data: any }) => {
    const { i18n, t } = useTranslation()

    const { getPagePath } = usePagePath()
    const scrolled25Send = useRef(false)
    const scrolled50Send = useRef(false)
    const scrolled75Send = useRef(false)
    const scrolled100Send = useRef(false)

    const pagePath = getPagePath(i18n.language)

    // Reset scroll event when page changes
    useEffect(() => {
        scrolled25Send.current = false
        scrolled50Send.current = false
        scrolled75Send.current = false
        scrolled100Send.current = false
        gtag('config', `${process.env.GA_ID}`, {
            // eslint-disable-next-line camelcase
            page_location: document.location,
        })
    }, [pagePath])
    const {
        handleSubmitStatus,
        handleFormSendStart,
        formSendStatus,
    } = useFormHandler()
    const onScroll = () => {
        const block = document.getElementById('blockF')
        const scrollPosition = block!.scrollTop
        const windowHeight = block!.clientHeight
        const bodyHeight = block!.scrollHeight
        const blockMod = document.getElementById('wrap')
        const scrollPositionMob = blockMod!.scrollTop
        const windowHeightMob = blockMod!.clientHeight
        const bodyHeightMob = blockMod!.scrollHeight
        setTimeout(() => {
            const trackScroll = () => {
                const scrolledRation = Math.ceil(
                    ((scrollPosition + windowHeight) / bodyHeight) * 100
                )
                if (
                    block &&
                    !scrolled100Send!.current &&
                    scrolledRation >= 100
                ) {
                    sendEvent('100', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled100Send!.current = true
                    return
                }

                if (block && !scrolled75Send!.current && scrolledRation >= 75) {
                    sendEvent('75', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled75Send!.current = true
                    return
                }

                if (block && !scrolled50Send!.current && scrolledRation >= 50) {
                    sendEvent('50', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled50Send!.current = true
                    return
                }

                if (block && !scrolled25Send!.current && scrolledRation >= 25) {
                    sendEvent('25', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled25Send!.current = true
                }
            }
            const trackScrollMob = () => {
                const scrolledRationMob = Math.ceil(
                    ((scrollPositionMob + windowHeightMob) / bodyHeightMob) *
                        100
                )
                if (
                    block &&
                    !scrolled100Send!.current &&
                    scrolledRationMob >= 100
                ) {
                    sendEvent('100', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled100Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled75Send!.current &&
                    scrolledRationMob >= 75
                ) {
                    sendEvent('75', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled75Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled50Send!.current &&
                    scrolledRationMob >= 50
                ) {
                    sendEvent('50', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled50Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled25Send!.current &&
                    scrolledRationMob >= 25
                ) {
                    sendEvent('25', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled25Send!.current = true
                }
            }
            trackScrollMob()
            trackScroll()
        }, 700)
    }

    return (
        <div>
            <HelmetFunc data={pageMetadata} />

            <Wrap id="wrap" onScroll={onScroll}>
                <MobileMenuPosadka />
                <PosadkaLinkBlock />
                <ProjectQuestionNew data={data} />
                <RunningLine inverse>{t('designProject99')}</RunningLine>
                <ContainerStyled columns={'1fr'}>
                    <PromoHeroColumn>
                        <FormTitle>{t('writeToUs')}</FormTitle>
                        <Form
                            buttonText={t('send')}
                            onFormSubmit={handleSubmitStatus}
                            formSendStatus={formSendStatus}
                            onFormSendStart={handleFormSendStart}
                            conversionType={'BottomFormMobilePosadka99'}
                            eventCategory={'Bottomform'}
                            formName={'Bottomform'}
                        >
                            {({ register, errors }: IChildrenProps) => (
                                <InputBlock>
                                    <PhoneInput
                                        labelBottom={-5}
                                        placeholder={t('InputPhone')}
                                        ref={register({
                                            minLength: 18,
                                            required: true,
                                        })}
                                        err={errors.phone}
                                    />

                                    <EmailInput
                                        labelBottom={-5}
                                        placeholder={t('InputEmail')}
                                        ref={register}
                                        err={errors.email}
                                    />
                                    <MessageInput
                                        labelBottom={-5}
                                        placeholder={t('InputQuestion')}
                                        ref={register}
                                        err={errors.message}
                                    />
                                </InputBlock>
                            )}
                        </Form>
                    </PromoHeroColumn>
                </ContainerStyled>
            </Wrap>

            <Desktop id="blockF" onScroll={onScroll}>
                <Header />
                <WrapDesktop>
                    <ProjectQuestionNewDesktop data={data} />
                    {/*
                    <Connection text={t('connection.text')}>
                        <ButtonWithModal
                            modalTitle={t('connection.modalTitle')}
                            modalDescription={t('connection.modalDescription')}
                            buttonLabel={t('connection.buttonLabel')}
                            placeholder={t('connection.placeholder')}
                            submitLabel={t('connection.submitLabel')}
                            tracking={{
                                conversionType: 'CallbackFromPosadka',
                                eventCategory: 'CallbackFromPosadka',
                            }}
                        />
                    </Connection> */}

                    <DefaultFormBlock
                        heroText={t('designQuestion')}
                        withPhoneMobile={false}
                        tracking={{
                            conversionType: 'FormPosadkaPageBottom',
                            eventCategory: 'Bottomform',
                        }}
                    />
                </WrapDesktop>
            </Desktop>
        </div>
    )
}

export default Posadka

export const query = graphql`
    query {
        allServicesNewYaml {
            edges {
                node {
                    questions {
                        title
                        question
                        answer
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
        allImageSharp {
            edges {
                node {
                    fluid(srcSetBreakpoints: [400]) {
                        originalName
                        ...GatsbyImageSharpFluid
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
        allProjectStructureQuestionYaml {
            edges {
                node {
                    formatedTitle: titleE
                    price
                    image
                    promo
                    items {
                        question
                        answer
                        svg
                    }
                    itemsNew {
                        question
                        answer
                        svg
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
    }
`
